import { graphql, useStaticQuery } from "gatsby";

const UseGetPostsForArchive = () => {
  const data = useStaticQuery(graphql`
    query AllPosts {
      en: allWpPost(
        sort: { order: DESC, fields: date }
        filter: { language: { code: { eq: EN } } }
      ) {
        edges {
          node {
            id
            title
            date(formatString: "MMM DD.MM.YY")
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: TRACED_SVG
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }

            author {
              node {
                name
              }
            }
            databaseId
            uri
            categories {
              nodes {
                name
                slug
                ancestors {
                  nodes {
                    name
                  }
                }
              }
            }
          }
        }
      }
      ar: allWpPost(
        sort: { order: DESC, fields: date }
        filter: { language: { code: { eq: AR } } }
      ) {
        edges {
          node {
            id
            title
            date(formatString: "D MMMM YYYY", locale: "ar_eg")
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 100
                      placeholder: TRACED_SVG
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }

            author {
              node {
                name
              }
            }
            databaseId
            uri
            categories {
              nodes {
                name
                slug
                ancestors {
                  nodes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

export default UseGetPostsForArchive;
