import React from "react";
import { Button, Card } from "react-bootstrap";
import postImg from "../assets/images/blog/blogPostThumb.png";
import LinesEllipsis from "react-lines-ellipsis";
import responsiveHOC from "react-lines-ellipsis/lib/responsiveHOC";
import parse from "html-react-parser";
import {
  FacebookShareCount,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import FacebookShareButton from "react-share/lib/FacebookShareButton";
import FacebookIcon from "react-share/lib/FacebookIcon";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { Helmet } from "react-helmet";

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const BlogArchiveSingle = ({ post }: any) => {
  const FeaturedImg = {
    data: post?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
      .images.fallback.src,
    alt: post?.featuredImage?.node?.alt || ``,
  };
  const isBrowser = () => typeof window !== "undefined";
  const excerpt = post.excerpt.replace(/<p>/g, "").replace(/<\/p>/g, "");
  return (
      <Card>
        <Card.Link href={post.uri}>
          <Card.Img
            variant="top"
            src={FeaturedImg.data}
            alt={FeaturedImg.alt}
          />
          <Card.Body>
            <Card.Title className="fw-bolder fs-5">
              <ResponsiveEllipsis
                text={post.title}
                maxLine="2"
                ellipsis="..."
                basedOn="letters"
              />
            </Card.Title>
            <Card.Text className="fw-semibold pb-2">
              <ResponsiveEllipsis
                text={excerpt}
                maxLine="2"
                ellipsis="..."
                basedOn="letters"
              />
            </Card.Text>

            <hr />

            <div className="postBottom">
              <span>{post.date}</span>
              <div className="postShareIcons">
                <FacebookShareButton
                  url={`${location.origin}/${post.uri}`}
                >
                  <FaFacebookF />
                </FacebookShareButton>
                <LinkedinShareButton
                  url={`${location.origin}/${post.uri}`}
                >
                  <FaLinkedinIn />
                </LinkedinShareButton>
                <TwitterShareButton
                  url={`${location.origin}/${post.uri}`}
                >
                  <FaTwitter />
                </TwitterShareButton>
              </div>
            </div>
          </Card.Body>
        </Card.Link>
      </Card>
  );
};

export default BlogArchiveSingle;
