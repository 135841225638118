import styled from "styled-components";
import blogArchiveHeadIMG from "../assets/images/blog/blogHead.png";
export const BlogArchiveWrapper = styled.div`
  min-height: 100vh;
  .head {
    background: url(${blogArchiveHeadIMG});
    height: 52vh;
    background-color: #d8efdb;
    background-position: 10% 70%;
    background-repeat: no-repeat;
    background-size: 100% 70%;
  }
  .head-info {
    text-align: center;
    background: #d8efdb;
    padding-bottom: 25px;
    p,
    h1 {
      max-width: 70%;
      margin: 0 auto;
    }
    h1 {
      font-weight: 800;
    }
    p {
      letter-spacing: normal;
      font-weight: 500;
    }
  }
  .posts {
    max-width: 75%;
    margin: 0 auto;
    padding: 10% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .card {
      margin-bottom: 40px;
      border-radius: 15px;
      background-color: rgb(255, 255, 255);
      box-shadow: -0.707px -0.707px 16px 0px rgba(0, 0, 0, 0.12);
      .card-link {
        text-decoration: none;
        color: #212529;
      }
      .card-body {
        .LinesEllipsis {
          height: 2em;
          line-height: 1em;
          overflow: hidden;
        }
      }
      .card-text {
        font-size: 0.9rem;
      }
      hr {
        border-bottom: 2px solid rgba(0, 0, 0, 0.22);
        margin: 12px 0;
      }
      .postBottom {
        display: flex;
        justify-content: space-between;
        font-size: 0.7rem;
        font-weight: 500;
        span {
          text-transform: uppercase;
        }
        .postShareIcons {
          /* display: flex;
          align-items: center; */
          svg {
            margin: 0 2px;
            &:hover {
              color: #38de3e;
            }
          }
          color: #908f8f;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .page-item {
      width: 10.5vw;
      display: flex;
      justify-content: center;
    }
    a {
      box-sizing: content-box;
      color: #244833;
      margin: 0 1.5vw;
      border-radius: 6px;
      background-color: #f3f8f8;
      border: 0;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .active > .page-link {
      background-color: #38de3e;
      color: white;
    }
  }

  @media screen and (min-width: 992px) {
    .head {
      transform: translateY(120px);
      height: 65vh;
      background-size: cover;
      background-position: 50% 68%;
    }
    .head-info {
      gap: 0px !important;
      padding-top: 160px;
      padding-bottom: 40px;
      h1 {
        font-size: 1.8rem !important;
      }
    }

     {
      /* posts style */
    }
    .posts {
      max-width: 60vw;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      padding: 5% 0;
      .card {
        margin: 10px;
        width: 395px;
        .card-img-top{
          height: 300px;
        }
      }
    }
    .pagination-container {
      margin: 0 auto;
      max-width: 54%;
      .pagination {
        justify-content: flex-end;
        margin-left: auto;
        max-width: 40%;
        .page-item {
          width: auto;
          margin: 0 5px;
        }
        a {
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .pagination-container {
    max-width: 50%;
    margin: 0 auto;
    }
  }
`;
