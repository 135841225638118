import React, { useState, useEffect } from "react";
import Layout, { useLanguage } from "../layout/layout";
import { BlogArchiveWrapper } from "./blogArchive.styled";
import BlogArchiveSingle from "./blogArchiveSingle";
import ReactPaginate from "react-paginate";
import { isEmpty } from "lodash";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import UseGetPostsForArchive from "../../hooks/useGetPostsForArchive";
import { Col, Spinner } from "react-bootstrap";

function Items({ currentItems }: any) {
  return (
    <div className="posts">
      {currentItems?.length > 0 &&
        currentItems.map((post: any) => (
          <BlogArchiveSingle key={post.node.id} post={post.node} />
        ))}
    </div>
  );
}

let trans = {
  en: {
    h1: "RUSH BLOG",
    txt: "View the latest news, announcements and tips from Rush."
  },
  ar: {
    h1: "مدونة راش",
    txt: "اخر الاخبار و التصريحات والتوصيات من برنامج راش"
  }
}

const BlogArchive = ({ itemsPerPage }: any) => {
  const [isLoading, setIsLoading] = useState(true);

  const { en, ar } = UseGetPostsForArchive();

  const [currentItems, setCurrentItems] = useState<any>(null);
  const [pageCount, setPageCount] = useState<number>(0);

  const [itemOffset, setItemOffset] = useState<number>(0);
  const lang = useLanguage();

  useEffect(() => {
    // Fetch items from another resources.
    if (lang == "en") {
      if (!isEmpty(en)) {
        setIsLoading(false);
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(en.edges.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(en.edges.length / itemsPerPage));
      }
    } else {
      if (!isEmpty(ar)) {
        setIsLoading(false);
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(ar.edges.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(ar.edges.length / itemsPerPage));
      }
    }
  }, [itemOffset, itemsPerPage, lang]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % en.edges.length;
    setItemOffset(newOffset);
  };

  return (
    <BlogArchiveWrapper>
      <div className="head"></div>
      <div className="head-info d-flex flex-column gap-2 justify-content-center align-items-center">
        <h1 className="fs-1">{lang == "en" ? trans.en.h1 : trans.ar.h1}</h1>
        <p>{lang == "en" ? trans.en.txt : trans.ar.txt}</p>
      </div>
      {isLoading ? (
        <Col
          md={3}
          sm={4}
          className="m-auto h-100vh d-flex flex-column align-items-center justify-content-center py-5"
        >
          <Spinner animation="border" />
        </Col>
      ) : (
        <>
          <Items currentItems={currentItems} />
          <div className="pagination-container">
            <ReactPaginate
              nextLabel={lang == "en" ? <FaAngleRight /> : <FaAngleLeft />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              pageCount={pageCount}
              previousLabel={lang == "en" ? <FaAngleLeft /> : <FaAngleRight />}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel={<FaAngleDoubleRight />}
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      )}
    </BlogArchiveWrapper>
  );
};

export default BlogArchive;
