import React from "react";
import BlogArchive from "../components/blog/blogArchive";
import type { HeadFC, PageProps } from "gatsby";
import Layout from "../components/layout/layout";

const Blog: React.FC<PageProps> = () => {
  const isBrowser = () => typeof window !== "undefined";

  let screenSize = isBrowser() && window.innerWidth;

  return (
    <Layout design={"fixed"}>
      <BlogArchive itemsPerPage={screenSize < 992 ? 2 : 4} />
    </Layout>
  );
};

export default Blog;
